@import '~vuetify/dist/vuetify.min.css';
@import '~leaflet/dist/leaflet.css';
@import '~leaflet-contextmenu/dist/leaflet.contextmenu.css';
@import '~leaflet-draw/dist/leaflet.draw.css';
@import '~leaflet-minimap/dist/Control.MiniMap.min.css';
* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  background-color: #000;
}


/* Original Font */

@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?reb7wy');
  src: url('./fonts/icomoon.eot?reb7wy#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?reb7wy') format('truetype'), url('./fonts/icomoon.woff?reb7wy') format('woff'), url('./fonts/icomoon.svg?reb7wy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ic-"],
[class*=" ic-"] {
  font-family: 'icomoon' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.ic-shelter-bill:before {
  content: "\e900";
}

.ic-shelter-emergency:before {
  content: "\e901";
}

.ic-shelter:before {
  content: "\e902";
}

.ic-shelter-tsunami:before {
  content: "\e903";
}

.application--wrap {
  max-width: none;
}

.md-16 {
  font-size: 16px;
}

.clickable {
  cursor: pointer;
}

// Leaflet
.leaflet-draw-actions li:first-child a {
  border-left: solid 2px #ccc;
}

.leaflet-draw-actions a {
  background-color: #e4f4f4;
  border: solid 2px #ccc;
}

.leaflet-draw.leaflet-control {
  display: none;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 499;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0;
}

.easy-button-button.free-active,
.easy-button-button.help-active,
.easy-button-button.guide-active {
  color: #1976D2;
}

.easy-button-button.fix-active {
  color: #a00;
}

.leaflet-bar button:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.leaflet-bar button:first-of-type {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.mapArea.leaflet-container .leaflet-control-attribution {
  display: none;
  font-size: 8px;
  text-align: right;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fff;
}

@media print {
  .mapArea.leaflet-container .leaflet-control-attribution {
    display: block;
  }
}

a.list__tile.list__tile--link {
  color: #333;
}

.list--two-line .list__tile {
  height: auto;
  margin: 1rem 0;
}

.overlay {
  z-index: 1000;
}

.dialog__content {
  z-index: 1001;
}

.card__text ul {
  margin: 0 1rem;
}

.alert {
  z-index: 1100;
  cursor: pointer;
}

.dialogFullContent {
  height: 90%;
  max-width: 900px;
  width: 80%;
  .cardText {
    overflow-x: hidden;
    height: 100%;
  }
  .v-text-field input[type="number"] {
    text-align: right;
  }
  .v-text-field__suffix {
    align-self: flex-end;
  }
}

.dialogMiniContent {
  z-index: 1003;
  max-width: 500px;
  width: 70%;
}

@media screen and (max-width: 500px) {
  .dialogFullContent,
  .dialogMiniContent {
    width: 100%;
    margin: auto 8px;
  }
}

.v-progress-circular.mapProgress {
  background-color: #eee;
  border-radius: 50px;
  margin: 0;
  z-index: 900;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: animProgress .5s infinite;
}


/* アニメーション */

@keyframes animProgress {
  50% {
    background-color: #d9e0ff;
  }
}

#main-toolbar {
  position: fixed;
  z-index: 500;
  top: auto;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  .toolbar__content {
    height: 48px !important;
  }
}

.toolbar {
  position: absolute;
  z-index: 500;
  bottom: 0;
}

.toolbarTitle {
  margin-left: 6rem;
}

.wrapSubTitle {
  font-size: 11px;
  white-space: normal;
  padding-left: 1rem;
}

@media print {
  .toolbar,
  .v-speed-dial,
  .leaflet-control-zoom,
  .leaflet-control-minimap,
  .leaflet-bar {
    display: none;
  }
}

.page {
  height: 100%;
}

.page .v-speed-dial {
  position: fixed;
  z-index: 500;
}

.page .btn--floating {
  position: relative;
}

#main-toolbar .toolbar__items>.btn--icon {
  margin: 0 8px;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.mapArea {
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapArea.topOff {
  margin-top: 60px;
}

.mapArea.whiteBack {
  background-color: #fff;
}

.vue2leaflet-map.mapArea {
  &.full {
    width: 100%;
    height: 100%;
  }
  &.a0-portrait {
    width: 841mm;
    height: 1189mm;
  }
  &.a0-landscape {
    width: 1189mm;
    height: 841mm;
  }
  &.a1-portrait {
    width: 594mm;
    height: 841mm;
  }
  &.a1-landscape {
    width: 841mm;
    height: 594mm;
  }
  &.a2-portrait {
    width: 420mm;
    height: 594mm;
  }
  &.a2-landscape {
    width: 594mm;
    height: 420mm;
  }
  &.a3-portrait {
    width: 297mm;
    height: 420mm;
  }
  &.a3-landscape {
    width: 420mm;
    height: 297mm;
  }
  &.a4-portrait {
    width: 210mm;
    height: 297mm;
  }
  &.a4-landscape {
    width: 297mm;
    height: 210mm;
  }
}

.leaflet-container a.leaflet-control-minimap-toggle-display {
  background-image: none;
  text-decoration: none;
  color: #666;
}

.leaflet-control-minimap-toggle-display:before {
  content: "\f06e";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 4px;
}

.previewArea {
  height: 300px;
  cursor: pointer;
  // background-color: #ccc;
  img {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
  }
}

@media print {
  .mapArea.topOff {
    margin-top: 0;
  }
}

.shirube-anim {
  // stroke:#000;
  // stroke-width:1px;
  fill: transparent;
  stroke-dasharray: 142px;
  stroke-dashoffset: 142px;
  animation: anim 2s ease-in 0s forwards;
}

@keyframes anim {
  0% {
    stroke-dashoffset: 142px;
  }
  30% {
    fill: transparent;
  }
  60% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1;
  }
}

@keyframes anime1 {
  0% {
    stroke-dashoffset: 20;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.shirfmovieube {
  fill: none; // stroke-dasharray: 15, 10;
  stroke-dasharray: 0.1, 12.9;
  stroke-linejoin: round;
  stroke-linecap: round;
  animation: anime1 3s linear 0s infinite reverse;
}

#topBar {
  height: 60px;
  z-index: 490;
  width: 100%;
  z-index: 491;
  color: #acc;
  position: fixed;
  font-size: 0.8ex;
  top: 0;
  left: 0;
}

#topBar .btn {
  height: 55px;
  margin-left: 2px;
  margin-right: 2px;
}

@media print {
  #topBar {
    display: none;
  }
}

#colorCard {
  // width: 100px;
  z-index: 491;
  position: fixed;
  opacity: 0.9;
  .v-list__tile__title {
    font-size: 1.4ex;
  }
  .v-list__tile--avatar {
    height: 24px;
    padding: 0 4px;
  }
  .v-list__tile__action {
    min-width: 24px;
  }
  &.legend--disabled {
    display: none;
  }
  &.legend--topleft {
    top: 4px;
  }
  &.legend--topright {
    top: 10px;
    right: 48px;
  }
  &.legend--topleft,
  &.legend--bottomleft {
    left: 4px;
  }
  &.legend--bottomright {
    right: 4px;
  }
  &.legend--bottomleft,
  &.legend--bottomright {
    bottom: 52px;
  }
}

@media print {
  #colorCard {
    &.legend--topright {
      right: 4px;
    }
  }
}

.guidePoint,
.guideArea,
.guideShirube-anim {
  animation: animPoint 3s infinite;
}

.guideArea {
  animation: animPoint 4s infinite;
}

@keyframes animPoint {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.pageBtn {
  min-width: 5em;
  width: 5em;
  font-size: 5ex;
}

.bigText {
  animation: animPoint 2s infinite;
  color: #ff0;
  font-size: 2.5ex;
}

.iconCardGrid .v-card {
  border-top-left-radius: 32px;
}

.helpDialogContent .v-card__text {
  text-align: left;
  max-height: 80%;
  // height: 80%;
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .v-image {
    max-width: 50%;
    margin: 1rem auto;
  }
}

.previewMask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  color: #fff;
  display: inline-grid;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.previewArea:hover .previewMask {
  opacity: 1;
}

.previewMask {
  transition: all 0.6s ease;
}

.v-dialog.paramsDialog {
  margin-bottom: 70px;
  &.dialogFullContent {
    height: 80%;
  }
  &:not(.v-dialog--fullscreen) {
    max-height: 80%;
  }
}

.mapArea .leaflet-bottom {
  padding-bottom: 68px;
}

.shirube-preview {
  z-index: 600;
}

.display-marker-field {
  .v-text-field__prefix {
    width: 80%;
    align-self: flex-end;
  }
  input {
    text-align: center;
    font-weight: bold;
    color: #f00 !important;
  }
}

div.shirube-preview .leaflet-control-shirube-scale.leaflet-control {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 4px;
}

.leaflet-control-shirube-scale {
  height: 40px;
  padding: 16px 2px 0;
  background-color: rgba(255, 255, 255, .9);
}

.leaflet-control-shirube-scale-ruler-block {
  overflow: hidden;
  position: absolute;
  top: 0%;
  height: 100%;
}

.leaflet-control-shirube-scale-ruler {
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 10px;
  background-color: White;
}

@media print {
  .leaflet-control-shirube-scale-ruler {
    print-color-adjust: exact;
  }
}

.leaflet-control-shirube-scale-label-div {
  position: relative;
  width: 100%;
  top: -26px;
}

.leaflet-control-shirube-scale-label-div2 {
  position: relative;
  width: 100%;
  top: 0;
}

.leaflet-control-shirube-scale-label,
.leaflet-control-shirube-scale-unit {
  position: absolute;
  color: #000;
  font-weight: bold;
  font-family: sans-serif;
  height: 5px;
  top: -1px;
  // text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.leaflet-control-shirube-scale-label {
  font-size: 10px;
  text-align: right;
}

.leaflet-control-shirube-scale-unit {
  font-size: 10px;
  text-align: right;
  right: 0;
}


/*.leaflet-control-shirube-scale-first-number {
    left: 45%;
}
.leaflet-control-shirube-scale-second-number {
    left: 95%;
}*/
